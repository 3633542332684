<section class="dashboard-body" [ngClass]="menuClassDeatails()">
    <div class="container-fluid">
        <div fxLayout="column" fxFlex="100%" class="">
            <div class="top-col">
                <h5 class="head-text color-green">{{header_text}} Class</h5>

            </div>

            <div fxLayout="row" fxFlex="100%">
                <form fxFlex="100%" [formGroup]="form">
                    <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
                    </formly-form>

                </form>



            </div>
            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end" fxLayoutGap="5px">

                <button mat-raised-button (click)="cancel()">
                    Cancel
                </button>
                <button mat-raised-button (click)="saveClass()" >
                    {{save_text}}
                </button>
            </div>


        </div>
    </div>
</section>