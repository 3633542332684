
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-select',
  template: `
<mat-form-field [class]="field.templateOptions?.class" appearance="outline" fxFlex="100" class="margin-space">
  <mat-label>{{to.label}}</mat-label>
  <mat-select [(ngModel)]="field.templateOptions.defaultSelected" [disabled]="to.disabled" [formControl]="formControl" [formlyAttributes]="field" (valueChange)="to.valueChange(field,$event)">
      <mat-option *ngFor="let food of to.options" [value]="food.value">
        {{food.label}}
      </mat-option>
    </mat-select>
    <div class="error_msg"  *ngIf="showError"><formly-validation-message [field]="field"></formly-validation-message></div>

</mat-form-field>
  `,
})
export class FormlyFieldCustomSelect extends FieldType {
}