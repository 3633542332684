import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component'
import { pairwise, startWith, map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.css']
})
export class AddClassComponent implements OnInit {
  showMainMenu: any;
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  menuNavigation = 'vertical'
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}

  formFields: Array<FormlyFieldConfig>

  model: any = {
 
  };
 
  fieldgroup = [];
 
  save_text = "Save"

  header_text = "Add"

  // for snackbar

  actionButtonLabel: string = '';

  action: boolean = true;

  setAutoHide: boolean = true;

  autoHide: number = 2000;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';

  verticalPosition: MatSnackBarVerticalPosition = 'top';

  config = new MatSnackBarConfig();
 
 
  constructor(

    private router: Router, private globalService: GlobalService, private _formBuilder: FormBuilder, public rootService: DataService, private snackBar: MatSnackBar

  ) {

    this.globalService.getObservable().subscribe((data) => {

      // console.log('globalService Data received: ', data);

      if (data.showMainMenu === true || data.showMainMenu === false) {

        this.showMainMenu = data.showMainMenu;

        // console.log('this.showMainMenu: ', this.showMainMenu);/

      } else if (data.colorMode) {

        this.colorMode = data.colorMode;

        // console.log('this.colorMode: ', this.colorMode);

      } else if (data.currentColor) {

        this.currentColor = data.currentColor;

        // console.log('this.currentColor: ', this.currentColor);

      } else if (data.menuNavigation) {

        this.menuNavigation = data.menuNavigation;

        // console.log('this.menuNavigation: ', this.menuNavigation);

      }

    });

  }
 
  ngOnInit(): void {

    this.config.verticalPosition = this.verticalPosition;

    this.config.horizontalPosition = this.horizontalPosition;

    this.config.duration = this.setAutoHide ? this.autoHide : 0;
 
    this.bind_formly_elements();

    this.editLoadData();
 
  }
 
  async editLoadData() {

    if (window.history.state.class_id != undefined && window.history.state.class_id != "") {

      this.save_text = "Update";

      this.header_text = "Update";
 
      var postObject1 = {};

      postObject1["category"] = 'Classes';

      postObject1["type"] = 'master';

      postObject1['product_id'] = window.history.state.class_id;

      postObject1["hash_value"] = await this.rootService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);
 
 
      this.rootService.getClasses(postObject1).subscribe((response: any) => {
 
        var resultsLength = response['data'].length;
 
        if (response['data'][0]['list'].length > 0) {

          var productList = response['data'][0]['list'];

          var productArray = [];

          productList.forEach(element => {

            var productJson = {

              "productName": element.name,

              "productUrl": element.product_url,

              "product_type": element.product_type,

            }

            productArray.push(productJson);

          });

        }

        // "startTime": response['data'][0]['start_date_time'],

        // "endTime": response['data'][0]['end_date_time'],

        // "endPaymentTime":response['data'][0]['end_payment_time'],

        // "02:30 AM",
 
        if (resultsLength > 0) {

         //changed

          const endPaymentTime = response['data'][0]['end_payment_time'] && response['data'][0]['end_payment_time'].length > 0

          ? this.getTimePickerFormat(response['data'][0]['end_payment_time'])

          : this.getTimePickerFormat(response['data'][0]['end_date_time']);

          this.model = {
 
          

            "product_id": response['data'][0]['product_id'],

            "name": response['data'][0]['name'],

            "description": response['data'][0]['description'],

            "refLanguage": response['data'][0]['language'],

            "refLocation": response['data'][0]['location'],

            "actualAmount": response['data'][0]['actual_amount'],

            "discountAmount": response['data'][0]['discount_amount'],

            "payableAmount": response['data'][0]['payable_amount'],

            "startDate": response['data'][0]['start_date_time'],

            "startTime": this.getTimePickerFormat(response['data'][0]['start_date_time']),

            "endTime": this.getTimePickerFormat(response['data'][0]['end_date_time']),

            "endPaymentTime": endPaymentTime,//changed

	          "meetingId": response['data'][0]['meeting_id'] ? response['data'][0]['meeting_id'] : "",

            "note": response['data'][0]['note'],

            "more_info": response['data'][0]['more_info'] ? response['data'][0]['more_info'] : false,

            "bg_image_url": response['data'][0]['bg_image_url'] ? response['data'][0]['bg_image_url'] : "classone",

            "productType": productArray

          }

          console.log(this.model)

        }

      }, (error) => {

        console.log('error is ', error)

      })
 
 
    }

  }

  getTimePickerFormat(time){

    var date = new Date(time);

    console.log(date.toISOString())

    console.log(date)

    return (date.getHours()>12 ?  (date.getHours() - 12) : date.getHours())+":"+date.getMinutes()+" "+ (date.getHours()>12 ? "PM" :"AM")

  }

  //Bind form elements

  bind_formly_elements() {

    var form_elements = [

      {
 
        key: 'product_id',

        type: 'inno-input',

        label: 'Product Id',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: "",

        fxFlex: 30,

        fxFlexOrder: 1

      },
 
      {
 
        key: 'name',

        type: 'inno-input',

        label: 'Name',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: "",

        fxFlex: 30,

        fxFlexOrder: 1

      },

      {
 
        key: 'description',

        type: 'inno-input',

        label: 'Description',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: "",

        fxFlex: 30,

        fxFlexOrder: 2

      },
 
      {
 
        key: 'refLanguage',

        type: 'inno-select',

        label: 'Language',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: [],

        fxFlex: 30,

        fxFlexOrder: 3,

        options: [{

          'value': "English", "label": "English"

        },

        {

          'value': "Tamil", "label": "Tamil"

        }]
 
      },
 
      {
 
        key: 'refLocation',

        type: 'inno-input',

        label: 'Location',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: [],

        fxFlex: 30,

        fxFlexOrder: 4
 
      },

      {
 
        key: 'startDate',

        type: 'inno-datepicker',

        label: 'Start Date',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 25,

        fxFlexOrder: 5

      },

      {
 
        key: 'startTime',

        type: 'inno-timepicker',

        label: 'Start Time',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 17,

        fxFlexOrder: 6

      },

      {
 
        key: 'endTime',

        type: 'inno-timepicker',

        label: 'End Time',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 17,

        fxFlexOrder: 7

      },
 
      {
 
        key: 'endPaymentTime',

        type: 'inno-timepicker',

        label: 'End Payment Time',

        childkey: '',

        hideExpression: '',

        required: false,//changed

        hide: false,

        value: '',

        fxFlex: 17,

        fxFlexOrder: 8
 
      },
 
      {
 
        key: 'actualAmount',

        type: 'inno-input',

        label: 'Actual Amount',

        childkey: "number",

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 30,

        fxFlexOrder: 9

      },

      {
 
        key: 'discountAmount',

        type: 'inno-input',

        label: 'Discount Amount',

        childkey: "number",

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 30,

        fxFlexOrder: 10

      },

      {
 
        key: 'payableAmount',

        type: 'inno-input',

        label: 'Payable Amount',

        childkey: "number",

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 30,

        fxFlexOrder: 11

      },

      {
 
        key: 'more_info',

        type: 'inno-select',

        label: 'More Information',

        childkey: '',

        hideExpression: '',

        required: true,

        hide: false,

        value: [],

        fxFlex: 30,

        fxFlexOrder: 12,

        options: [{

          'value': true, "label": "Yes"

        },

        {

          'value': false, "label": "No"

        }]
 
      },

      {
 
        key: 'note',

        type: 'inno-input',

        label: 'Note',

        childkey: "",

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 30,

        fxFlexOrder: 13

      },
 
      

      {
 
        key: 'meetingId',

        type: 'inno-input',

        label: 'Meeting ID',

        hideExpression: '',

        required: true,

        hide: false,

        value: '',

        fxFlex: 30,

        fxFlexOrder: 10

      },


      // {
 
      //   key: 'refProductType',

      //   type: 'repeat',

      //   label: 'Product Type',

      //   childkey: '',

      //   hideExpression: '',

      //   required: true,

      //   hide: false,

      //   value: [],

      //   fxFlex:30,

      //   fxFlexOrder:11
 
      // },
 
 
    ]
 
 
    for (var i = 0; i < form_elements.length; i++) {
 
      var field_json =

      {

        key: form_elements[i].key,

        type: form_elements[i].type,
 
        class: "element-width",

        // defaultValue:form_elements[i].defaultValue,

        templateOptions: {

          label: form_elements[i].label,

          placeholder: form_elements[i].label,

          description: form_elements[i].label,

          type: form_elements[i].childkey,

          fxFlexOrder: form_elements[i].fxFlexOrder,
 
          fxFlex: form_elements[i].fxFlex,

          required: form_elements[i].required,

          options: form_elements[i].options,
 
          valueChange: (field, value) => {

            console.log(value, field, 'valuechanges')
 
            //  this.bind_values(field)

          },
 
        },
 
 
      };
 
      this.fieldgroup.push(field_json);
 
 
 
    }

    this.fieldgroup.push({

      key: 'productType',

      type: 'repeat',
 
      wrappers: ['inno-wrapper'],

      templateOptions: {

        addText: "Add Product Type",

        label: "Product type",

        fxFlex: "100%",

        fxLayout: 'column',

        fxFlexOrder: 15,
 
      },

      fieldArray: {

        fieldGroup: [

          {

            key: 'product_type',

            type: 'inno-select',

            defaultValue: "image",

            templateOptions: {

              label: 'Product Type',

              placeholder: 'Product Type',

              required: true,

              fxFlex: "30%",

              fxFlexOrder: 3,

              options: [{ value: "image", label: "Image" },

              { value: "article", label: "PDF" }, { value: "audio", label: "Audio" },

              { value: "video", label: "Video" },],

              valueChange: () => { }

            },

          },

          {

            key: 'productName',

            type: 'inno-input',
 
            templateOptions: {

              label: 'Name',

              placeholder: 'Name',

              description: 'Name',

              required: true,

              // class:'custom-width',

              fxFlex: "25%",

              fxFlexOrder: 2,
 
              valueChange: () => { }

            },

          },

          {

            key: 'productUrl',

            type: 'inno-input',

            templateOptions: {

              label: 'Url',

              placeholder: 'Enter Url',

              class: 'custom-width',

              required: true,

              fxFlex: "45%",

              fxFlexOrder: 3,
 
              valueChange: (field, $event) => { }

            },
 
          },
 
        ]

      },
 
    })

    this.formFields = [

      {

        type: 'flex-layout-formly',

        templateOptions: {

          fxLayout: 'column',
 
        },

        fieldGroup: this.fieldgroup

      }]

    console.log(this.fieldgroup, 'fieldgroup')
 
  }

  cancel() {

    this.router.navigateByUrl('/list-classes',{state:{}});

  }

  getTimeFromDate(timestamp) {

    let date = new Date();

    let hours = date.getHours();

    let minutes = date.getMinutes();

    let seconds = date.getSeconds();

    return hours + "," + minutes + "," + seconds

  }

  getTimeFromDateNew(mydate,timestamp) {

    let date = new Date(mydate);

    console.log(timestamp)

    console.log(this.parsetime(timestamp)["hours"]+"",":"+this.parsetime(timestamp)["minutes"])

    date.setHours( this.parsetime(timestamp)["hours"])

    date.setMinutes(this.parsetime(timestamp)["minutes"])

    console.log(date)

    let hours = date.getHours();

    let minutes = date.getMinutes();

    let seconds = date.getSeconds();

    return hours + "," + minutes + "," + seconds

  }
 
  parsetime(time) {

      let hours = parseInt(time.split(":")[0]);

      let minutes = parseInt(time.split(":")[1].split(" ")[0]);

      if (time.includes("PM") && hours !== 12) hours += 12;

      return {hours:hours,minutes:minutes};

    }

parseDaytime(time) {

  console.log(time)

    let hours = parseInt(time.split(":")[0]);

    console.log(hours)

    console.log(time.split(":")[1].split(" ")[0])

    let minutes = parseInt(time.split(":")[1].split(" ")[0]);

    console.log(minutes)

    if (time.includes("PM") && hours !== 12) hours += 12;

    console.log(hours)

    return 1000/*ms*/ * 60/*s*/ * (hours * 60 + minutes);

  }

  async saveClass() {

    console.log(this.model);
 
    var end_date_time_event = new Date(this.model.startDate);

    var end_time = this.getTimeFromDateNew(this.model.startDate, this.model.endTime);

    var split_end_time = end_time.split(',');

    end_date_time_event.setHours(parseInt(split_end_time[0]), parseInt(split_end_time[1]), parseInt(split_end_time[2]));

    //changed part 

    var end_payment_time_event = new Date(this.model.startDate);

    var end_payment_time;

    if (this.model.endPaymentTime) {

        end_payment_time = this.getTimeFromDateNew(this.model.startDate, this.model.endPaymentTime);

    } else {

        end_payment_time = this.getTimeFromDateNew(this.model.startDate, this.model.endTime);

    }

    var split_end_payment_time = end_payment_time.split(',');

    end_payment_time_event.setHours(parseInt(split_end_payment_time[0]), parseInt(split_end_payment_time[1]), parseInt(split_end_payment_time[2]));
 
    var start_date_time_event = new Date(this.model.startDate);

    var start_time = this.getTimeFromDateNew(this.model.startDate, this.model.startTime);

    var split_start_time = start_time.split(',');

    start_date_time_event.setHours(parseInt(split_start_time[0]), parseInt(split_start_time[1]), parseInt(split_start_time[2]));
 
    console.log(start_date_time_event);

    console.log(end_date_time_event);

    console.log(end_payment_time_event);
 
    var product_type_array = [];

    if (this.model.productType.length > 0) {

        var productTypes = this.model.productType;

        productTypes.forEach(element => {

            var product_type_json = {

                "product_id": this.model.product_id,

                "name": element.productName,

                "description": "",

                "language": this.model.refLanguage,

                "location": this.model.refLocation,

                "product_url": element.productUrl,

                "product_type": element.product_type,

                "bg_image_url": "classone",

                "fg_image_url": "",

                "start_date_time": start_date_time_event,

                "end_date_time": end_date_time_event,

                "end_payment_time": end_payment_time_event,

                "note": this.model.note,

                "list": []

            };

            product_type_array.push(product_type_json);

        });

    }
 
 
 
    // expected output: Tue Aug 19 1975 20:15:30 GMT+0200 (CEST)

    // (note: your timezone may vary)
 
    // event.setHours(20, 21, 22);

    // console.log(event);
 
    var mainbj = {};

    var obj = {};

    var langugaeObj = {};

    var classObj = {};

    console.log(this.model)

    classObj = [{

      "category": "Online Live Workshop",

      "product_id": this.model.product_id,

      "name": this.model.name,

      "description": this.model.description,

      "language": this.model.refLanguage,

      "location": this.model.refLocation,

      "product_url": "",

      "note": this.model.note,

      "actual_amount": this.model.actualAmount,

      "discount_amount": this.model.discountAmount,

      "payable_amount": this.model.payableAmount,

      "start_date_time": start_date_time_event,

      "end_date_time": end_date_time_event,

      "end_payment_time":end_payment_time_event,

      "fg_image_url": "",

      "bg_image_url": this.model.bg_image_url ? this.model.bg_image_url : "classone",

      "appstore_product_id": "",

      "appstore_actual_amount": "",

      "appstore_discount_amount": "",

      "appstore_payable_amount": "",

      "type":"",

      "meeting_id": this.model.meetingId,

      "more_info":this.model.more_info,

      "list": product_type_array

    }]

    langugaeObj = [{

      "product_id": "OnClsEng001",

      "language": this.model.refLanguage,

      "name": this.model.name,

      "list": classObj

    }]

    obj['active_status'] = true;

    obj['type'] = 'master';

    obj['category'] = 'Classes';

    obj['list'] = langugaeObj;

    mainbj['type'] = "product";

    mainbj['loop'] = 1;

    mainbj['obj'] = obj;

    if (window.history.state.class_id != undefined && window.history.state.class_id != "") {

      mainbj['product_id'] = window.history.state.class_id;

    }
 
    // Get classes object

    var postObject1 = {};

    postObject1["category"] = 'Classes';

    postObject1["type"] = 'master';

    postObject1["hash_value"] = await this.rootService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);
 
 
    // For checking already exists  

    this.rootService.getClasses(postObject1).subscribe((response: any) => {
 
      console.log(mainbj)

      var resultsLength = response['data'].length;

      if (resultsLength > 0) {

        var id = response['data'][0]['_id'];

        mainbj['_id'] = id;

        console.log(mainbj)

        this.rootService.updateClasses(mainbj).subscribe((response: any) => {

          this.router.navigateByUrl('/list-classes',{state:{}});

          this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);

          console.log(response, 'response')

        }, (error) => {

          console.log('error is ', error)

        })

      }

      else {

        console.log(mainbj)

        this.rootService.saveClasses(mainbj).subscribe((response: any) => {

          this.router.navigateByUrl('/list-classes',{state:{}});

          this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);

          console.log(response, 'response')

        }, (error) => {

          console.log('error is ', error)

        })
 
      }

    }, (error) => {

      console.log('error is ', error)

    })
 
 
 
    console.log(mainbj)

  }

  adjustForTimezone(date:Date):Date{

    var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;

    date.setHours(date.getHours() - 5);

    date.setMinutes(date.getMinutes() - 30);

    return date

}

  menuClassDeatails(): string {

    if (this.menuNavigation === 'horizontal') {

      return 'full-width';

    } else {

      if (this.showMainMenu === false) {

        return 'd-none2';

      } else {

        return '';

      }

    }

  }
 
}
